import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/font.ts\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--inter\"}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/font.ts\",\"import\":\"Mulish\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--mulish\"}],\"variableName\":\"mulish\"}");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/font.ts\",\"import\":\"Sahitya\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--sahitya\"}],\"variableName\":\"sahitya\"}");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/font.ts\",\"import\":\"Philosopher\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--philosopher\"}],\"variableName\":\"philosopher\"}");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/font.ts\",\"import\":\"Oldenburg\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--oldenburg\"}],\"variableName\":\"oldenburg\"}");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/font.ts\",\"import\":\"Open_Sans\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--open_sans\"}],\"variableName\":\"open_sans\"}");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/font.ts\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--poppins\"}],\"variableName\":\"poppins\"}");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/swiper/swiper.scss");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/swiper/modules/effect-fade.scss");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/public/assets/fontawesome/all.min.css");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/public/assets/fontawesome/sharp-solid.min.css");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/public/assets/fontawesome/sharp-regular.min.css");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/public/assets/scss/style.scss");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/bootstrap/dist/css/bootstrap.min.css");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/component/utils/Cursor.tsx");
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/redux/provider.tsx")